window.addEventListener('load',function(){
    var popups = document.querySelectorAll('.popup');
    if (Cookies.get('cookie_consent') === 'set' && popups.length > 0) {
        popups.forEach(function(popup){
            var thatPopup = popup;
            var thisID = thatPopup.getAttribute('data-popupid');
            var popups_seen = typeof Cookies.get('popups_seen') == 'undefined' ? '' : Cookies.get('popups_seen');
            if (popups_seen.search(thisID) < 0) {
                var time = thatPopup.getAttribute('data-eventtimer');
                setTimeout(() => {
                   var modal = document.getElementById(thatPopup.getAttribute('id'));
                    new Modal(modal, {
                        'backdrop': 'static',
                        'keyboard': false
                    }).show();
                 }, time);
                
            }
        });
    }
});

if (document.querySelector('.popup')) {
    document.querySelector('.popup').addEventListener('hidden.bs.modal', function (event) {
        if (navigator.cookieEnabled) {
            var modal = event.target;
            savePopupAsSeen(modal);
        }
    });

    document.querySelector('.popups-seen-klicker').addEventListener('click', function (event) {
        if (navigator.cookieEnabled) {
            event.preventDefault();
            var modal = event.target.closest('.modal');
            savePopupAsSeen(modal);

            if (event.target.target === '_blank') {
                window.open(event.target.href, '_blank');
            } else {
                window.location = event.target.href;
            }
        }
    });
}

var savePopupAsSeen = (modal) => {
    var modalID = modal.getAttribute('data-popupid');
    if (typeof Cookies.get('popups_seen') !== "undefined") {
        /**
         * Wenn es den Cookie für Pop-Ups schon gibt,
         * prüfe ob dieses PopUp schon gesehen wurde
         * also in der Liste auftaucht.
         *
         * Falls nicht, hänge es als "gesehen" an die Liste an.
         */
        var oldList = Cookies.get('popups_seen');
        if (oldList.search(modalID) < 0) {
            Cookies.set('popups_seen', oldList + '.' + modalID, {expires: 14, secure: true, sameSite: 'strict', path: '/'});
        }
    } else {
        Cookies.set('popups_seen', modalID, {expires: 14, secure: true, sameSite: 'strict', path: '/'});
    }
};
